import { SxProps, styled } from '@mui/material'
import { useTranslation } from 'react-i18next'
import { MouseEventHandler, useState } from 'react'

import StateImage, { ImageType } from 'components/commons/StateImage'
import Box from 'components/commons/Box'
import { ButtonVariant } from 'components/commons/Button'
import Stack from 'components/commons/Stack'
import ActionButton from 'components/commons/Button/Action'
import theme from 'theme'

import {
  ErrorCountdown,
  ErrorDesc,
  ErrorLabel,
} from 'containers/domains/Wallet/DirectPaymentModal/styled'
import { getIdToken } from 'helpers/auth'
import isEmpty from 'helpers/is-empty'
import RequestIdentifierLabel from '../RequestIdentifierLabel'
import { ErrorActionType } from 'types/common'
import { getPartnerVisibility } from 'helpers/partner'

interface Action {
  id: string
  onClick: MouseEventHandler<HTMLButtonElement>
  title: string
  sx?: SxProps
  buttonVariant?: ButtonVariant
  buttonType?: 'primary' | 'secondary'
  disabled?: boolean
}

interface ErrorResponseContent {
  imageType?: ImageType
  title?: string // This can be a localized string
  desc?: string // This can be a localized string
  actions: Action[]
  sxDesc?: SxProps
  imageId?: string
  hideLabel?: boolean
}
interface ErrorResponse {
  [key: string]: ErrorResponseContent
}

const ContainerBtn = styled(Stack)({
  display: 'block',
  width: '100%',
  flexDirection: 'row',
  marginTop: '24px',
  justifyContent: 'center',
})

export default function ErrorResponseCode({
  code = 'GENERAL_ERROR_PAYMENT',
  showCTA,
  onCTA,
  data,
  sxContainer,
  disabled,
  requestId,
}: {
  code?: string
  showCTA?: boolean
  onCTA?: Function | any
  data?: any
  sxContainer?: SxProps
  disabled?: boolean
  requestId?: string
}) {
  const { t } = useTranslation()
  const visibility = getPartnerVisibility()

  const [countDownToRetry, setCountDownToRetry] = useState(0)

  const CARD_PAYMENT_ERROR: Action[] = [
    {
      id: 'b-common-change-card',
      onClick: () => onCTA(ErrorActionType.CHANGE_CARD),
      title: t('key_try_with_different_card'),
    },
    {
      id: getIdToken()
        ? 'b-common-redirect-to-transaction-history'
        : 'b-common-back-to-event',
      buttonVariant: 'outlined',
      onClick: () => onCTA(ErrorActionType.TRANSACTION_HISTORY),
      title: getIdToken()
        ? t('key_check_transaction_history')
        : t('key_back_to_event_details'),
    },
  ]

  const ERROR_RESPONSE_CONTENT: ErrorResponse = {
    INSUFFICIENT_QUOTA: {
      imageType: 'ticket-not-available',
      title: t('eventTicketSelectionSoldOutTitle'),
      desc: t('eventTicketSelectionSoldOutDescription'),
      actions: [
        ...(visibility?.renderButtonOtherEvent
          ? [
              {
                id: 'b-common-browse-event',
                onClick: () => onCTA(ErrorActionType.BROWSE_EVENT),
                title: t('eventTicketSelectionSeeOtherEvent'),
              },
            ]
          : []),
        {
          id: 'b-common-back-to-event',
          onClick: () => onCTA(ErrorActionType.BACK_TO_EVENT),
          title: t('eventParticipantFormBackToEvent'),
          buttonVariant: 'outlined',
        },
      ],
    },
    PRODUCT_UNAVAILABLE: {
      imageType: 'calendar',
      title: t('eventUnpublishedTitle'),
      desc: t('eventUnpublishedDescription'),
      actions: [
        {
          id: 'b-common-browse-event',
          onClick: () => onCTA(ErrorActionType.BROWSE_EVENT),
          title: t('eventUnpublishedButtonLabel'),
        },
      ],
    },
    SALE_NOT_STARTED: {
      imageType: 'date-time',
      title: t('eventDetailsErrorSaleNotStartedTitle'),
      desc: t('eventDetailsErrorSaleNotStartedSubtitle'),
      actions: [
        {
          id: 'b-common-close-error',
          onClick: () => onCTA(ErrorActionType.CLOSE),
          title: t('close'),
        },
      ],
    },
    USER_OVERBOOKED: {
      imageType: 'ticket-not-available',
      title: t('eventUserOverbookedTitle'),
      desc: t('eventUserOverbookedDescription'),
      actions: [
        {
          id: 'b-common-close-error',
          onClick: () => onCTA(ErrorActionType.CLOSE),
          title: t('close'),
        },
      ],
    },
    INVALID_SALES_PERIOD: {
      imageType: 'calendar',
      title: t('eventTicketSelectionSalesEndedTitle'),
      desc: t('eventTicketSelectionSalesEndedDescription'),
      actions: [
        {
          id: 'b-common-back-to-event',
          onClick: () => onCTA(ErrorActionType.BACK_TO_EVENT),
          title: t('eventParticipantFormBackToEvent'),
        },
      ],
    },
    ID_NUMBER_ALREADY_USED: {
      imageType: 'user-alert',
      title: t('key_duplicate_id_number_title'),
      desc: t('key_duplicate_id_number_desc', {
        id_number: data?.id_card?.join(', '),
      }),
      actions: [
        {
          id: 'b-common-close-error',
          onClick: () => onCTA(ErrorActionType.CLOSE),
          title: t('key_too_many_request_share_cta'),
        },
        {
          id: 'b-common-contact-to-cs',
          onClick: () => onCTA(ErrorActionType.CONTACT_CS),
          title: t('contactCs'),
          buttonVariant: 'outlined',
        },
      ],
    },
    NOT_ENOUGH_QUOTA: {
      imageType: 'ticket-not-available',
      title: t('eventDetailsErrorQuantityExceedsQuotaTitle'),
      desc: t('eventDetailsQuantityExceedsQuotaSubtitle'),
      actions: [
        {
          id: 'b-common-refresh',
          onClick: () => onCTA(ErrorActionType.REFRESH),
          title: t('refresh'),
        },
      ],
    },
    FAIL_CHECKOUT: {
      imageType: 'system-error',
      title: t('vaPaymentInstructionWidgetErrorTitle'),
      desc: visibility?.useThemePartner
        ? t('otherApiErrorSubtitle')
        : t('key_payment_general_error_after_checkout_message'),
      actions: [
        {
          id: 'b-common-retry-countdown',
          onClick: () => handleCountdownClick(),
          title: t('retry'),
          disabled: disabled || countDownToRetry !== 0,
        },
        {
          id: getIdToken()
            ? 'b-common-redirect-to-transaction-history'
            : 'b-common-back-to-event',
          onClick: () => onCTA(ErrorActionType.TRANSACTION_HISTORY),
          title: getIdToken()
            ? t('key_check_transaction_history')
            : t('key_back_to_event_details'),
          buttonVariant: 'outlined',
          disabled: disabled || countDownToRetry !== 0,
        },
      ],
    },
    FAIL: {
      imageType: 'system-error',
      title: t('otherApiErrorTitle'),
      desc: t('vaPaymentInstructionWidgetErrorDesc'),
      actions: [
        {
          id: 'b-common-close-error',
          onClick: () => onCTA(ErrorActionType.CLOSE),
          title: t('retry'),
        },
      ],
    },
    INSUFFICIENT_BALANCE_COIN: {
      imageType: 'error',
      title: t('paymentWidgetInsufficientCoinBalanceTitle'),
      desc: t('paymentWidgetInsufficientCoinBalanceDesc'),
      actions: [
        {
          id: 'b-common-top-up-coin',
          onClick: () => onCTA(ErrorActionType.TOPUP),
          title: t('topUpCoinButtonTitle'),
        },
        {
          id: 'b-common-back',
          onClick: () => onCTA(ErrorActionType.BACK),
          title: t('back'),
          buttonVariant: 'outlined' as ButtonVariant,
        },
      ],
    },
    BAD_REQUEST_COIN: {
      imageType: 'error',
      title: t('topUpCoinExceededLimitTitle'),
      desc: data,
      sxDesc: { textTransform: 'capitalize' },
      actions: [
        {
          id: 'b-common-close-error',
          onClick: () => onCTA(ErrorActionType.CLOSE),
          title: t('back'),
        },
      ],
    },
    NOT_FOUND: {
      imageType: 'no-respond',
      title: t('vaPaymentInstructionWidgetCanceledTitle'),
      desc: t('vaPaymentInstructionWidgetCanceledDesc'),
      actions: [
        {
          id: 'b-common-close-error',
          onClick: () => onCTA(ErrorActionType.CLOSE),
          title: t('close'),
        },
      ],
    },
    SESSION_FULLY_BOOKED: {
      imageType: 'unhappy_booking',
      title: t('sessionFullyBookedWidgetTitle'),
      desc: t('purchaseTicketBottomSheetSessionFullyBookedSubtitle'),
      actions: [
        {
          id: 'b-common-close-error',
          onClick: () => onCTA(ErrorActionType.CLOSE),
          title: t('refresh'),
        },
      ],
    },
    GUEST_PENDING_TRANSACTION: {
      imageType: 'guest_pending',
      title: t('key_previous_order_not_paid'),
      desc: data?.body || t('vaPaymentInstructionWidgetErrorDesc'),
      actions: [
        {
          id: 'b-common-continue-payment',
          onClick: () => onCTA(ErrorActionType.CONTINUE),
          title: t(
            'guestPurchasePendingPaymentCompletePaymentButton',
          ),
          disabled,
        },
        ...(!getIdToken()
          ? [
              {
                id: 'b-common-cancel-payment',
                onClick: () => onCTA(ErrorActionType.CANCEL),
                title: t('transactionHistoryScreenCancelBtn'),
                disabled,
                buttonVariant: 'outlined' as ButtonVariant,
              },
            ]
          : []),
      ],
    },
    BOOKING_EXPIRED: {
      imageType: 'no-respond',
      title: t('key_time_is_up_title'),
      desc: t('key_time_is_up_desc'),
      actions: [
        {
          disabled,
          id: 'b-common-select-ticket',
          onClick: () => onCTA(ErrorActionType.SELECT_TICKET),
          title: t('eventTicketSelectTicketButton'),
        },
      ],
    },
    EDIT_CONFIRMATION: {
      imageType: 'user-alert',
      title: t('key_change_contact_info_title'),
      desc: t('key_change_contact_info_desc'),
      actions: [
        {
          disabled,
          id: 'b-common-edit-booking',
          onClick: () => onCTA(ErrorActionType.EDIT_BOOKING),
          title: t('key_change_contact_info_cta'),
        },
        {
          disabled,
          buttonVariant: 'outlined',
          id: 'b-common-continue-booking',
          onClick: () => onCTA(ErrorActionType.CONTINUE_BOOKING),
          title: t('key_cancel'),
        },
      ],
    },
    CANCEL_CONFIRMATION: {
      imageId: 'c-cancel-confirmation-error-modal-img',
      imageType: 'ticket-not-available',
      title: t('key_order_will_canceled_title'),
      desc: t('key_order_will_canceled_desc'),
      actions: [
        {
          disabled,
          id: 'b-continue-booking-error-modal',
          onClick: () => onCTA(ErrorActionType.CONTINUE_BOOKING),
          title: t('key_continue_order_btn'),
        },
        {
          disabled,
          buttonVariant: 'outlined',
          id: 'b-cancel-booking-error-modal',
          onClick: () => onCTA(ErrorActionType.CANCEL_BOOKING),
          title: t('transactionHistoryScreenCancelBtn'),
        },
      ],
    },
    BOOKING_ONGOING: {
      imageId: 'c-ongoing-booking-error-modal-img',
      imageType: 'ticket-not-available',
      title: t('key_pending_order'),
      desc: t('key_pending_order_desc'),
      actions: [
        {
          id: 'b-finish-booking-error-modal',
          disabled,
          onClick: () => onCTA(ErrorActionType.FINISH_BOOKING),
          title: t('key_complete_Order'),
        },
        {
          id: 'b-create-new-booking-error-modal',
          disabled,
          buttonVariant: 'outlined',
          onClick: () => onCTA(ErrorActionType.CREATE_NEW_BOOKING),
          title: t('key_create_new_order'),
        },
      ],
    },
    CHECKOUT_INVALID_STATUS: {
      imageType: 'session-almost-full',
      title: t('key_order_invalid'),
      desc: t('key_order_invalid_desc'),
      actions: [
        {
          id: 'b-common-reorder',
          disabled,
          onClick: () => onCTA(ErrorActionType.BACK_TO_EVENT),
          title: t('key_back_to_event_details'),
        },
      ],
    },
    AUTHENTICATION_FAILED: {
      imageType: 'card-failed',
      title: t('key_authentication_failed'),
      desc: t('key_authentication_failed_desc'),
      actions: [
        {
          id: 'b-common-check-card-detail',
          onClick: () => onCTA(ErrorActionType.CHECK_CARD_DETAIL),
          title: t('key_check_card_details'),
        },
      ],
    },
    API_VALIDATION_ERROR: {
      imageType: 'system-error',
      title: t('key_payment_failed'),
      desc: t('key_payment_failed_desc'),
      actions: [
        {
          id: 'b-common-change-payment-method',
          onClick: () => onCTA(ErrorActionType.CLOSE),
          title: t('key_change_payment_method'),
        },
        {
          id: 'b-common-contact-to-cs',
          buttonVariant: 'outlined',
          onClick: () => onCTA(ErrorActionType.CONTACT_CS),
          title: t('contactCs'),
        },
      ],
    },
    MID_NOT_FOUND_ERROR: {
      imageType: 'system-error',
      title: t('key_payment_failed'),
      desc: t('key_payment_failed_after_checkout_desc'),
      actions: [
        {
          id: 'b-common-change-card',
          onClick: () => onCTA(ErrorActionType.CHANGE_CARD),
          title: t('key_try_with_different_card'),
        },
        {
          id: 'b-common-contact-to-cs',
          buttonVariant: 'outlined',
          onClick: () => onCTA(ErrorActionType.CONTACT_CS),
          title: t('contactCs'),
        },
      ],
    },
    VERIFICATION_TIMEOUT_ERROR: {
      imageType: 'pending-withdraw',
      title: t('key_payment_time_expired'),
      desc: t('key_payment_time_expired_desc'),
      actions: [
        {
          id: 'b-common-reorder',
          onClick: () => onCTA(ErrorActionType.RE_BOOK),
          title: t('key_reorder_btn'),
        },
      ],
    },
    TEMPORARY_SERVICE_ERROR: {
      imageType: 'card-failed',
      title: t(
        'key_temporary_problems_occurred_in_contacting_the_bank',
      ),
      desc: t(
        'key_temporary_problems_occurred_in_contacting_the_bank_desc',
      ),
      actions: [
        {
          id: 'b-common-retry',
          onClick: () => onCTA(ErrorActionType.RETRY),
          title: t('retry'),
        },
        {
          id: 'b-common-contact-to-cs',
          buttonVariant: 'outlined',
          onClick: () => onCTA(ErrorActionType.CONTACT_CS),
          title: t('contactCs'),
        },
      ],
    },
    ACCOUNT_NUMBER_INVALID_ERROR: {
      imageType: 'card-failed',
      title: t('key_invalid_card_number'),
      desc: t('key_invalid_card_number_desc'),
      actions: [
        {
          id: 'b-common-check-card-detail',
          onClick: () => onCTA(ErrorActionType.CHECK_CARD_DETAIL),
          title: t('key_check_card_details'),
        },
      ],
    },
    ISSUER_UNAVAILABLE: {
      imageType: 'card-bank',
      title: t('key_card_issuer_cannot_be_used_yet'),
      desc: t(
        'key_card_issuer_cannot_be_used_yet_after_checkout_desc',
      ),
      actions: CARD_PAYMENT_ERROR,
    },
    DECLINED_BY_ISSUER: {
      imageType: 'card-failed',
      title: t('key_card_rejected_by_bank_declined'),
      desc: t(
        'key_card_rejected_by_bank_declined_after_checkout_desc',
      ),
      actions: CARD_PAYMENT_ERROR,
    },
    EXPIRED_CARD: {
      imageType: 'card-failed',
      title: t('key_card_has_expired'),
      desc: t('key_card_has_expired_after_checkout_desc'),
      actions: CARD_PAYMENT_ERROR,
    },
    ISSUER_SUSPECT_FRAUD: {
      imageType: 'card-failed',
      title: t('key_card_rejected_by_bank_declined'),
      desc: t(
        'key_card_rejected_by_bank_or_fraud_after_checkout_desc',
      ),
      actions: CARD_PAYMENT_ERROR,
    },
    INACTIVE_OR_UNAUTHORIZED_CARD: {
      imageType: 'card-failed',
      title: t('key_card_rejected_by_bank_declined'),
      desc: t(
        'key_card_rejected_by_bank_declined_after_checkout_desc',
      ),
      actions: CARD_PAYMENT_ERROR,
    },
    INSUFFICIENT_BALANCE: {
      imageType: 'state-money-currency',
      title: t('key_insufficient_card_limit'),
      desc: t('key_insufficient_balance_after_checkout_desc'),
      actions: CARD_PAYMENT_ERROR,
    },
    INVALID_CARD: {
      imageType: 'card-failed',
      title: t('key_invalid_card'),
      desc: t('key_invalid_card_after_checkout_desc'),
      actions: [
        {
          id: 'b-common-check-card-detail',
          onClick: () => onCTA(ErrorActionType.CHECK_CARD_DETAIL),
          title: t('key_check_card_details'),
        },
        {
          id: getIdToken()
            ? 'b-common-redirect-to-transaction-history'
            : 'b-common-back-to-event',
          buttonVariant: 'outlined',
          onClick: () => onCTA(ErrorActionType.TRANSACTION_HISTORY),
          title: getIdToken()
            ? t('key_check_transaction_history')
            : t('key_back_to_event_details'),
        },
      ],
    },
    INVALID_CVV: {
      imageType: 'card-failed',
      title: t('key_invalid_CVN_CVV'),
      desc: t('key_invalid_CVN_CVV_after_checkout_desc'),
      actions: [
        {
          id: 'b-common-check-card-detail',
          onClick: () => onCTA(ErrorActionType.CHECK_CARD_DETAIL),
          title: t('key_check_card_code'),
        },
        {
          id: getIdToken()
            ? 'b-common-redirect-to-transaction-history'
            : 'b-common-back-to-event',
          buttonVariant: 'outlined',
          onClick: () => onCTA(ErrorActionType.TRANSACTION_HISTORY),
          title: getIdToken()
            ? t('key_check_transaction_history')
            : t('key_back_to_event_details'),
        },
      ],
    },
    STOLEN_CARD: {
      imageType: 'card-failed',
      title: t('key_card_rejected_by_bank_declined'),
      desc: t(
        'key_card_rejected_by_bank_or_stolen_after_checkout_desc',
      ),
      actions: CARD_PAYMENT_ERROR,
    },
    PROCESSOR_TIMEOUT: {
      title: t('key_no_connection'),
      desc: t('key_no_connection_desc'),
      actions: [
        {
          id: 'b-common-retry-connect',
          onClick: () => onCTA(ErrorActionType.KEY_NO_CONNECTION),
          title: t('retry'),
        },
        {
          id: getIdToken()
            ? 'b-common-redirect-to-transaction-history'
            : 'b-common-back-to-event',
          buttonVariant: 'outlined',
          onClick: () => onCTA(ErrorActionType.TRANSACTION_HISTORY),
          title: getIdToken()
            ? t('key_check_transaction_history')
            : t('key_back_to_event_details'),
        },
      ],
      imageType: 'no-internet-connection',
    },
    ECONNABORTED: {
      title: t('key_no_connection'),
      desc: t('key_no_connection_desc'),
      actions: [
        {
          id: 'b-common-retry-connect',
          onClick: () => onCTA(ErrorActionType.RETRY),
          title: t('retry'),
        },
      ],
      imageType: 'no-internet-connection',
    },
    CONNECTION_ERROR: {
      title: t('key_no_connection'),
      desc: t('key_no_connection_desc'),
      actions: [
        {
          id: 'b-common-refresh-connection',
          onClick: () => onCTA(ErrorActionType.REFRESH),
          title: t('refresh'),
        },
      ],
      imageType: 'no-internet-connection',
    },
    FRAUD_RISK_BLOCKED: {
      imageType: 'card-failed',
      title: t('key_card_rejected_by_bank_declined'),
      desc: t(
        'key_card_rejected_by_bank_or_fraud_after_checkout_desc',
      ),
      actions: CARD_PAYMENT_ERROR,
    },
    INVALID_CVN_LENGTH_ERROR: {
      imageType: 'card-failed',
      title: t('key_invalid_CVN_CVV'),
      desc: t('key_invalid_CVN_CVV_after_checkout_desc'),
      actions: CARD_PAYMENT_ERROR,
    },
    AMOUNT_GREATER_THAN_AUTHENTICATED_ERROR: {
      imageType: 'state-money-currency',
      title: t('key_transaction_nominal_exceeds_limit'),
      desc: t(
        'key_transaction_nominal_exceeds_limit_after_checkout_desc',
      ),
      actions: CARD_PAYMENT_ERROR,
    },
    AMOUNT_BELOW_MINIMUM_LIMIT: {
      imageType: 'state-money-currency',
      title: t('key_transaction_amount_insufficient'),
      desc: t(
        'key_transaction_amount_insufficient_after_checkout_desc',
      ),
      actions: CARD_PAYMENT_ERROR,
    },
    AMOUNT_ABOVE_MAXIMUM_LIMIT: {
      imageType: 'state-money-currency',
      title: t('key_transaction_nominal_exceeds_limit'),
      desc: t(
        'key_transaction_nominal_exceeds_limit_after_checkout_desc',
      ),
      actions: CARD_PAYMENT_ERROR,
    },
    INCOMPLETE_AUTHENTICATION: {
      imageType: 'card-failed',
      title: t('key_authentication_failed'),
      desc: t('key_authentication_failed_after_checkout_desc'),
      actions: [
        {
          id: 'b-common-check-card-detail',
          onClick: () => onCTA(ErrorActionType.CHECK_CARD_DETAIL),
          title: t('key_check_card_details'),
        },
        {
          id: getIdToken()
            ? 'b-common-redirect-to-transaction-history'
            : 'b-common-back-to-event',
          buttonVariant: 'outlined',
          onClick: () => onCTA(ErrorActionType.TRANSACTION_HISTORY),
          title: getIdToken()
            ? t('key_check_transaction_history')
            : t('key_back_to_event_details'),
        },
      ],
    },
    GENERAL_ERROR: {
      imageType: 'system-error',
      title: t('key_authentication_failed'),
      desc: t('key_authentication_failed_after_checkout_desc'),
      actions: [
        {
          id: 'b-common-check-card-detail',
          onClick: () => onCTA(ErrorActionType.CHECK_CARD_DETAIL),
          title: t('key_check_card_details'),
        },
        {
          id: getIdToken()
            ? 'b-common-redirect-to-transaction-history'
            : 'b-common-back-to-event',
          buttonVariant: 'outlined',
          onClick: () => onCTA(ErrorActionType.TRANSACTION_HISTORY),
          title: getIdToken()
            ? t('key_check_transaction_history')
            : t('key_back_to_event_details'),
        },
      ],
    },
    AUTHENTICATION_ALREADY_USED_ERROR: {
      imageType: 'system-error',
      title: t('vaPaymentInstructionWidgetErrorTitle'),
      desc: visibility?.useThemePartner
        ? t('otherApiErrorSubtitle')
        : t('key_payment_general_error_after_checkout_message'),
      actions: [
        {
          disabled: disabled || countDownToRetry !== 0,
          id: 'b-common-retry-countdown',
          onClick: () => handleCountdownClick(),
          title: t('retry'),
        },
        {
          id: getIdToken()
            ? 'b-common-redirect-to-transaction-history'
            : 'b-common-back-to-event',
          buttonVariant: 'outlined',
          onClick: () => onCTA(ErrorActionType.TRANSACTION_HISTORY),
          title: getIdToken()
            ? t('key_check_transaction_history')
            : t('key_back_to_event_details'),
        },
      ],
    },
    FAILED_CANCEL: {
      imageType: 'user-alert',
      title: t('key_order_cannot_cancelled'),
      desc: t('key_order_cannot_cancelled_desc'),
      actions: [
        {
          id: 'b-common-contact-to-cs',
          onClick: () => onCTA(ErrorActionType.CONTACT_CS),
          title: t('contactCs'),
        },
      ],
    },
    FAILED_CONTINUE: {
      imageType: 'error',
      title: t('key_purchase_failed'),
      desc: t('key_check_status_payment_failed'),
      actions: [
        {
          id: 'b-common-repeat-transaction',
          onClick: () => onCTA(),
          title: t('key_repeat_transaction'),
        },
      ],
    },
    REDIRECT_CONFIRMATION: {
      imageType: 'share-content',
      title: t('key_payment_not_completed'),
      desc: t('key_complete_your_payment_now'),
      actions: [
        {
          disabled: disabled || countDownToRetry !== 0,
          buttonVariant: 'outlined',
          id: 'b-common-back-to-event-detail',
          onClick: () => onCTA(ErrorActionType.BACK),
          title: t('key_back_to_event_details'),
        },
        {
          id: 'b-common-continue-payment',
          disabled: disabled || countDownToRetry !== 0,
          onClick: () => onCTA(ErrorActionType.CONTINUE),
          title: t('key_continue_payment'),
        },
      ],
    },
    GENERAL_ERROR_PAYMENT: {
      imageType: 'system-error',
      title: t('otherApiErrorTitle'),
      desc: t('vaPaymentInstructionWidgetErrorDesc'),
      actions: [
        {
          id: 'b-common-close',
          onClick: () => onCTA(ErrorActionType.CLOSE),
          title: t('retry'),
        },
      ],
    },
    INVALID_VOUCHER: {
      imageType: 'error',
      title: t('key_failed_use_voucher'),
      desc: t('key_failed_use_voucher_desc'),
      actions: [
        {
          id: 'b-common-close-voucher-error',
          onClick: () => onCTA(ErrorActionType.CLOSE),
          title: t('understand'),
        },
      ],
    },
    EXIT_TICKET_LIST: {
      imageType: 'state-features',
      title: t('key_leave_page'),
      desc: t('key_leave_page_desc'),
      actions: [
        {
          id: '',
          buttonVariant: 'outlined',
          onClick: () => onCTA(ErrorActionType.CLOSE),
          title: t('cancel'),
        },
        {
          id: '',
          onClick: () => onCTA(ErrorActionType.EXIT),
          title: t('key_exit'),
        },
      ],
    },
    BOOKING_LIST_ERROR: {
      imageType: 'error',
      title: t('key_failed_load_purchase_title'),
      desc: t('key_failed_load_purchase_desc'),
      actions: [
        {
          id: '',
          disabled,
          onClick: () => onCTA(ErrorActionType.REFRESH),
          title: t('tryAgain'),
        },
      ],
    },
    BOOKING_DELIVERY_ERROR: {
      imageType: 'error',
      title: t('key_failed_load_ticket_title'),
      desc: t('key_failed_load_ticket_desc'),
      actions: [
        {
          id: '',
          disabled,
          buttonVariant: 'nude',
          buttonType: 'primary',
          onClick: () => onCTA(ErrorActionType.REFRESH),
          title: t('refresh'),
          sx: {
            border: `1px solid ${theme.palette.primary.main}`,
          },
        },
      ],
    },
    OTP_TOO_MANY_REQUESTS: {
      imageType: 'error',
      title: t('key_please_wait_verif_tittle'),
      desc: t('key_please_wait_verif_desc', {
        remainingTime:
          data?.seconds > 60
            ? `${Math.ceil(data?.seconds / 60)} ${t('minutes')}`
            : `${data?.seconds} ${t('seconds')} `,
      }),
      actions: [
        {
          id: '',
          disabled,
          buttonVariant: 'outlined',
          onClick: () => onCTA(ErrorActionType.RETRY),
          title: t('retry'),
        },
      ],
    },
    PRIVATE_CODE_USED: {
      imageType: 'user-alert',
      title: t('key_field_used_title', {
        field_label: data?.label,
      }),
      desc: t('key_field_used_desc', {
        field_label: data?.label,
        field_value: data?.values?.join(', '),
      }),
      actions: [
        {
          id: '',
          disabled,
          onClick: () => onCTA(ErrorActionType.CLOSE),
          title: t('key_change_value_cta', {
            field_label: data?.label,
          }),
        },
      ],
    },
    PRIVATE_CODE_INVALID: {
      imageType: 'user-alert',
      title: t('key_field_invalid_title', {
        field_label: data?.label,
      }),
      desc: t('key_field_invalid_desc', {
        field_label: data?.label,
        field_value: data?.values?.join(', '),
      }),
      actions: [
        {
          id: '',
          disabled,
          onClick: () => onCTA(ErrorActionType.CLOSE),
          title: t('key_change_value_cta', {
            field_label: data?.label,
          }),
        },
      ],
    },
    NOT_FOUND_PAYMENT: {
      imageType: 'empty-data',
      title: t('key_payment_being_processed'),
      desc: visibility?.useThemePartner
        ? t('otherApiErrorSubtitle')
        : t('key_payment_in_progress_desc'),
      actions: [
        {
          id: '',
          disabled,
          onClick: () => onCTA(ErrorActionType.BACK_TO_EVENT),
          title: t('key_back_to_event_details'),
        },
      ],
    },
    CHANNEL_UNAVAILABLE: {
      imageType: 'state-failed-payment-method',
      title: t('key_payment_method_is_not_available'),
      desc: t('key_payment_channel_experienced_unexpected_problems'),
      actions: [
        {
          id: '',
          disabled,
          onClick: () => onCTA(ErrorActionType.TRANSACTION_HISTORY),
          title: t('key_check_transaction_history'),
        },
      ],
    },
    UNSUPPORTED_CURRENCY: {
      imageType: 'state-money-currency',
      title: t('key_payment_method_cannot_be_used'),
      desc: t('key_payment_method_cannot_use_currency'),
      actions: [
        {
          id: '',
          disabled,
          onClick: () => onCTA(ErrorActionType.TRANSACTION_HISTORY),
          title: t('key_check_transaction_history'),
        },
      ],
    },
    CHARGE_LIMIT_EXCEEDED: {
      imageType: 'state-too-many-attempts',
      title: t('key_too_many_attempts_in_a_short_time'),
      desc: t('key_you_have_tried_more_than_3_times'),
      actions: [
        {
          id: '',
          disabled: disabled || countDownToRetry !== 0,
          onClick: () => handleCountdownClick(),
          title: t('key_try_again_in_a_moment'),
        },
      ],
    },
  }

  const handleCountdownClick = () => {
    setCountDownToRetry(3)
    const timer = setInterval(() => {
      setCountDownToRetry((prevCount) => {
        if (prevCount <= 1) {
          clearInterval(timer)
          onCTA(ErrorActionType.RETRY)
          return 0
        }
        return prevCount - 1
      })
    }, 1000)
  }

  const errorCode =
    isEmpty(code) || !ERROR_RESPONSE_CONTENT[code]
      ? 'GENERAL_ERROR_PAYMENT'
      : code

  const { imageType, title, desc, actions, sxDesc, hideLabel } =
    ERROR_RESPONSE_CONTENT[errorCode]

  return (
    <Box sx={sxContainer}>
      <StateImage
        type={imageType}
        sx={{
          display: 'block',
          marginLeft: 'auto',
          marginRight: 'auto',
          marginBottom: '24px',
        }}
      />

      {!hideLabel && (
        <>
          <ErrorLabel>{title}</ErrorLabel>
          <ErrorDesc sx={sxDesc}>{desc}</ErrorDesc>
          {countDownToRetry !== 0 && (
            <ErrorCountdown>
              {' '}
              {t('in_n_sec', { n: countDownToRetry })}
            </ErrorCountdown>
          )}
        </>
      )}
      {requestId && <RequestIdentifierLabel id={requestId} />}
      {showCTA && (
        <ContainerBtn spacing="16px">
          {actions.map((item) => (
            <ActionButton
              data-testid={item.id}
              key={item.id}
              id={item.id}
              fullWidth
              onClick={item.onClick}
              sx={item.sx}
              buttonVariant={item.buttonVariant}
              buttonType={item.buttonType}
              disabled={item.disabled}
            >
              {item.title}
            </ActionButton>
          ))}
        </ContainerBtn>
      )}
    </Box>
  )
}
