import { IPartnerConfig } from 'types/partner'

export const configPartner: Record<string, IPartnerConfig> = {
  default: {
    useThemePartner: false,
    layout: {
      useLiteHeader: false,
      showHeader: true,
      showFooter: true,
      showAppBanner: true,
    },

    showBreadcrumb: true,
    showShareAndReport: true,
    showTabCreatorAndDesc: true,
    showTabCreatorAndDescLite: false,

    productCreator: {
      showViewMyProfile: true,
      isEnabledClick: true,
    },

    eventInfoSection: {
      isEnabledClick: false,
    },

    showCommunitySection: true,
    showEventFooterInfoSection: true,

    isEnabledClick: true,

    showCoin: true,
    showBackToHomeBtn: true,
    showBackBtnContent: true,

    showTnCParagraph: true,
    showPriceBreakdown: true,
    showPaymentMethod: true,
    showTotalPayment: true,
    renderButtonOtherEvent: true,
    bookingTimer: {
      colorText: '#FFFFFF',
    },
  },
  bri: {
    useThemePartner: true,
    layout: {
      useLiteHeader: true,
      showHeader: false,
      showFooter: false,
      showAppBanner: false,
    },

    showBreadcrumb: false,
    showShareAndReport: false,
    showTabCreatorAndDesc: false,
    showTabCreatorAndDescLite: true,

    productCreator: {
      showViewMyProfile: false,
      isEnabledClick: false,
    },

    eventInfoSection: {
      isEnabledClick: false,
    },

    showCommunitySection: false,
    showEventFooterInfoSection: false,

    isEnabledClick: false,
    showCoin: false,
    showBackToHomeBtn: false,
    showBackBtnContent: false,

    showTnCParagraph: false,
    showPriceBreakdown: false,
    showPaymentMethod: false,
    showTotalPayment: false,
    renderButtonOtherEvent: false,
    bookingTimer: {
      colorText: '#000000',
    },
  },
}
