import { useEffect, useState } from 'react'
import { TextField, useTheme } from '@mui/material'
import ErrorIcon from '@mui/icons-material/Error'

import Stack from 'components/commons/Stack'
import Grid from 'components/commons/Grid'

import { IInputField } from '..'
import { StyledTextAreaCount } from '../styled'
import { useTranslation } from 'react-i18next'
import CountrySelect from 'components/domains/Event/Booking/SelectCountryCodes'

const autoCompleteId = {
  full_name: 'name',
  phone_number: 'tel',
  email: 'email',
}

// field validation function
export function validateField({
  fieldValidation,
  fieldValue,
  fieldLabel,
  fieldName,
  customErrorMessage,
  formValue,
  t,
}): string | null {
  if (!fieldValidation.required && !fieldValue.length) {
    return null
  }

  if (fieldValidation.is_unique) {
    if (formValue) {
      const occurances = formValue
        ?.map((value) => value[fieldName])
        .reduce((a, b) => (b === fieldValue ? a + 1 : a), 0)
      if (occurances > 1)
        return t('key_error_identity_number', {
          fieldLabel: 'Nomor KTP / Paspor',
        })
    }
  }

  if (fieldValidation.required && !fieldValue?.length) {
    return (
      customErrorMessage?.required ||
      t('dynamicFormRequiredErrorText', {
        fieldLabel: fieldLabel,
      })
    )
  }

  if (
    fieldValidation.custom_validation &&
    !new RegExp(fieldValidation.custom_validation).test(fieldValue)
  ) {
    return (
      customErrorMessage?.custom_validation ||
      t('key_invalid_label', {
        label: fieldLabel,
      })
    )
  }

  if (
    fieldValidation.min_length > 0 &&
    fieldValue.length < fieldValidation.min_length
  ) {
    return (
      customErrorMessage?.min_length ||
      t('key_min_lengh_min_char', {
        label: fieldLabel,
        min: fieldValidation.min_length,
      })
    )
  }

  if (
    fieldValidation.max_length > 0 &&
    fieldValue.length > fieldValidation.max_length
  ) {
    return (
      customErrorMessage?.max_length ||
      t('key_max_lengh_max_char2', {
        label: fieldLabel,
        max: fieldValidation.max_length,
      })
    )
  }

  if (
    fieldValidation.min_value > 0 &&
    Number(fieldValue) < fieldValidation.min_value
  ) {
    return (
      customErrorMessage?.min_value ||
      t('dynamicFormMinValueErrorText', {
        fieldLabel: fieldLabel,
        minValue: fieldValidation.min_value,
      })
    )
  }

  if (
    fieldValidation.max_value > 0 &&
    Number(fieldValue) > fieldValidation.max_value
  ) {
    return (
      customErrorMessage?.max_value ||
      t('dynamicFormMaxValueErrorText', {
        fieldLabel: fieldLabel,
        maxValue: fieldValidation.max_value,
      })
    )
  }

  return null
}
// main component function
export default function CustomTextField({
  multiline = false,
  ...props
}: IInputField) {
  const { t } = useTranslation()
  const theme = useTheme()
  const [isTouched, setIsTouched] = useState(false)
  const [textAreaCount, setTextAreaCount] = useState(0)

  useEffect(() => {
    if (props.value && !isTouched) {
      setIsTouched(true)
    }
  }, [isTouched, props.value])

  const validationResult = validateField({
    fieldValidation: props.validation,
    fieldValue: props.value,
    fieldLabel: t(props.label),
    fieldName: props.name,
    customErrorMessage: props.validation_error_message,
    formValue: props.formValue,
    t: t,
  })

  useEffect(() => {
    if (props.defaultValue) {
      setTextAreaCount(props.defaultValue?.length || 0)
      props.onValueChange(
        props.defaultValue,
        validateField({
          fieldValidation: props.validation,
          fieldValue: props.defaultValue,
          fieldLabel: t(props.label),
          fieldName: props.name,
          customErrorMessage: props.validation_error_message,
          formValue: props.formValue,
          t: t,
        }) === null,
      )
    }
  }, [])

  const textFieldProps = {
    error: isTouched && validationResult !== null,
    multiline: multiline,
    rows: multiline ? 4 : 1,
    disabled: props.disabled,
    fullWidth: true,
    placeholder: t(props.placeholder),
    value: props.value,
    name: props.name,
    autoComplete: autoCompleteId[props.name],
    className: 'i-custom-field-text',
    onChange: (e) => {
      setIsTouched(true)
      setTextAreaCount(e.target.value.length)
      props.onValueChange(
        e.target.value,
        validateField({
          fieldValidation: props.validation,
          fieldValue: e.target.value,
          fieldLabel: t(props.label),
          fieldName: props.name,
          customErrorMessage: props.validation_error_message,
          formValue: props.formValue,
          t: t,
        }) === null,
      )
    },
    type: props.validation.number_only ? 'number' : 'text',
    onKeyDown: (e) => {
      if (e.key === 'ArrowUp' || e.key === 'ArrowDown') {
        e.preventDefault()
      }
    },
    onWheel: (e) => (e.target as HTMLElement).blur(),
    helperText: (
      <>
        {props.type === 'PARAGRAPH' &&
          props.validation.max_length > 0 && (
            <StyledTextAreaCount>
              {textAreaCount}/{props.validation.max_length}
            </StyledTextAreaCount>
          )}
        {isTouched && validationResult !== null && (
          <Stack direction={'row'} alignItems={'center'}>
            <ErrorIcon
              sx={{ fontSize: 'inherit', marginRight: '4px' }}
            />
            {validationResult}
          </Stack>
        )}
      </>
    ),
    sx: {
      '.MuiFormHelperText-root': {
        margin:
          props.type === 'PARAGRAPH' &&
          props.validation.max_length > 0
            ? '0px !important'
            : undefined,
      },
      '.MuiFormHelperText-root.Mui-error': {
        color: theme.palette.error.main,
        marginLeft: 0,
      },
      'input::-webkit-inner-spin-button': {
        '-webkit-appearance': 'none',
      },
      '.MuiOutlinedInput-root': {
        borderBottomLeftRadius:
          props.type === 'PARAGRAPH' &&
          props.validation.max_length > 0
            ? '0px !important'
            : undefined,
        borderBottomRightRadius:
          props.type === 'PARAGRAPH' &&
          props.validation.max_length > 0
            ? '0px !important'
            : undefined,
        background: theme.palette.background.tertiery,
        ...theme.typography.normalRegular,
      },
      '& .MuiOutlinedInput-notchedOutline': {
        border: 'none',
        ':hover': {
          border: 'none',
        },
      },
      ...props.sx,
    },
    InputProps: props.InputProps,
  }

  return (
    <>
      {props.type === 'PHONE' ? (
        <Grid container spacing={0} sx={{ marginTop: '15px' }}>
          <Grid item xs={3} lg={2}>
            <CountrySelect
              value={props.areaCode}
              onChanged={props.onAreaCodeChange}
              sx={{
                maxHeight: '53px',
                lineHeight: '53px',
                border: 'none',
                borderRadius: '8px',
                marginRight: '6px',
              }}
            />
          </Grid>
          <Grid item xs={9} lg={10}>
            <TextField {...textFieldProps} />
          </Grid>
        </Grid>
      ) : (
        <Grid item xs={12}>
          <TextField {...textFieldProps} />
        </Grid>
      )}
    </>
  )
}
