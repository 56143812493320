/* eslint-disable no-unused-vars */

import { IPaymentDetails } from 'hooks/domains/Payment/useXendit'
import { IPaymentCheckoutDirectBody } from 'stores/domains/Payment/SelectedProducts/interface'
import { IInquiryTopup } from 'stores/domains/Wallet/models/TopUp/interface'

export interface IProductPreCheckout {
  price: number
  product_uid: string
  qty: number
  title: string
}

export interface IAdditionalFee {
  code: string
  name: string
  note: string
  value: number
}

export type PaymentMethodCode =
  | 'H2H_BCA_VA'
  | 'BSI_VA'
  | 'BJB_VA'
  | 'BRI_VA'
  | 'MANDIRI_VA'
  | 'BNI_VA'
  | 'PERMATA_VA'
  | 'CIMB_VA'
  | 'PAYMENT_CARD'
  | 'DANA'
  | 'OVO'
  | 'LINKAJA'
  | 'GOPAY'
  | 'INDOMART'
  | 'ALFAMART'
  | 'KREDIVO'
  | 'QR'
  | 'BRI_DIRECT_DEBIT'
  | 'BRIMO'
  | 'TIPTIP_COIN'
  | 'OTHER_METHOD'
  | ''

export type PaymentMethodGroupCode =
  | 'VA'
  | 'CARD'
  | 'E-WALLET'
  | 'RETAIL-OUTLET'
  | 'PAY-LATER'
  | 'QR-PAYMENT'
  | 'DIRECT-DEBIT'
  | 'TIPTIP'
  | ''
export interface IPaymentMethod {
  admin_fee_title: string
  black_icon: string
  code: PaymentMethodCode
  coin: {
    balance: number
    rate: number
    sub_total: number
    total_additional_fee: number
    total_price: number
    voucher_discount: number
  }
  detail_url: string
  disable_voucher_reason: string
  icon: string
  info: string
  name: string
  payment_channel: string
  price: {
    sub_total: number
    total_additional_fee: number
    total_price: number
    total_price_before_discount: number
    voucher_discount: number
    additional_fee: Array<IAdditionalFee>
  }
  sub_total: string
  tax: number
  total: number
  transaction_fee: number
  voucher_code: string
  voucher_info: string
  voucher_discount: number
  disable_reason: string
  group_code: string
  group_name: string
  sort: number
  bin_cards: Array<IBin>
  payment_information: string
  additional_info?: string // conditional for store phone number when using ovo
}

interface PaymentInfo {
  invoice_id: string
  invoice_url: string
  payment_channel: string
  sub_total: number
  tax: number
  total: number
  transaction_fee: number
  admin_fee: number
  price: number
  total_price_before_discount: number
}

interface IPreCheckoutSession {
  booking: number
  created_at: string
  payment_info: PaymentInfo
  redirection_link: string
  session_id: string
  status: number
  total_booked: number
  user_mask_id: string
}

interface IPreCheckoutSubscription {
  duration: number
  package_id: string
  payment_info: PaymentInfo
  title: string
}

export interface IPreCheckoutData
  extends IPreCheckoutSession,
    IPreCheckoutSubscription,
    IInquiryTopup {
  payment_method: IPaymentMethod[]
  products: IProductPreCheckout
  price_detail: number
  base_price: number
  invoice_id: string
  use_default_admin_fee_title: boolean
  default_payment_methods: IPaymentMethod[]
  payment_method_groups: IPaymentMethodGroup[]
  payment_information: string
}

export interface IPaymentHandlerStrategy {
  idempotentKey?: string

  // digital content
  products?: IPaymentCheckoutDirectBody
  salePrice?: number

  // params top up coin
  topupInquiry?: IInquiryTopup

  // params for subscription
  subscriptionDurationId?: string

  // live session
  sessionId?: string

  // event
  bookSessionId?: string
  skipToPayment?: boolean
}

export interface ICheckoutParams {
  voucherCode?: string
  paymentMethodCode?: PaymentMethodCode
  paymentId?: string

  // subscription
  invoiceId?: string
  username?: string

  // event
  promoterToken?: string
  cardNumber?: string
  authId?: string
  count?: number
  tokenId?: string
  productUid?: string
  acquirer?: string
  phoneNumber?: string
}

type ItemType =
  | 'event'
  | 'subscription'
  | 'session'
  | 'content'
  | 'coin'

export type PaymentHandlerStrategy = (
  params: IPaymentHandlerStrategy,
) => {
  preCheckout: () => Promise<IPreCheckoutResult>
  checkout: ({
    voucherCode,
    paymentMethodCode,
    paymentId,
    promoterToken,
  }: ICheckoutParams) => Promise<any>
  itemType?: ItemType
  skipToPayment?: boolean
}

export type PaymentStatus = 'PENDING' | 'PAID' | 'COMPLETED'

export interface ICheckoutData {
  status: PaymentStatus
  invoice_id: string
  invoice_url: string
  payment_method_code: PaymentMethodCode
  coin_info: {
    previous_balance: number
    total_purchase: number
    remaining_balance: number
  }
  paid_at: string
  expiry_date: string
  payment_gateway_response: {
    error_code: string
    failure_reason: string
    eci: string
    cvn_code: string
  }
  e_wallet_response: {
    desktop_web_checkout_url: string
    mobile_web_checkout_url: string
  }
}

export interface IPaymentStatus {
  invoice_id: string
  payment_method_code: PaymentMethodCode
  payment_method_name: string
  status: PaymentStatus
  paid_at: string
  checkout_at: string
  expired_at: string
  total: number
  coin_info?: {
    coin_balance: number
    top_up: number
  }
  ticket: string
}

export enum PaymentMethodEnum {
  H2H_BCA_VA = 0,
  CREDIT_CARD = 1,
  TIPTIP_COIN = 2,
  OTHER_METHOD = 3,
}

export enum AvailableCardBrandEnum {
  JCB = 'jcb',
  VISA = 'visa',
  MASTERCARD = 'mastercard',
}
export interface IPreCheckoutResult {
  result: IPreCheckoutData
  isCoinPaymentDefault: boolean
  skipPreCheckout: boolean
  skipToPayment?: boolean
}

export interface IPaymentInstructionList {
  expiry_date: string
  time_remaining: number
  expiry_message: string
  icon: string
  is_timer_active: boolean
  instructions: Array<PaymentInstructionItem>
  invoice_id: string
  payment_method_group_code: PaymentMethodGroupCode
  payment_method_group_name: string
  payment_method_code: PaymentMethodCode
  payment_method_name: string
  total: number
  va: string
  va_name: string
  va_detail: {
    va: string
    va_name: string
  }
  qr_detail: {
    qr_string: string
  }
  e_wallet_detail: {
    phone_number: string
  }
}

interface PaymentInstructionItem {
  tab_name: BCAPaymentInstructionTab
  points: Array<PaymentInstructionPoint>
  step?: string
  mobile_body: string
}

interface PaymentInstructionPoint {
  title: string
  steps: Array<string>
}

export interface IBin {
  bank: string
  bins: Array<string>
}
export interface IPaymentMethodGroup {
  group_code: string
  group_name: string
  payment_methods: Array<IPaymentMethod>
}
export type BCAPaymentInstructionTab =
  | 'ATM'
  | 'IBanking'
  | 'myBCA'
  | 'BCA mobile'

export interface IXenditTokenizationResponse {
  id: string
  status: 'IN_REVIEW' | 'FAILED' | 'VERIFIED'
  failure_reason: string
  payer_authentication_url: string
  masked_card_number: string
  card_info: {
    bank: string
    brand: string
    country: string
    type: string
    fingerprint: string
  }
}

export interface IInstallmentPlan {
  count: number
  interval: string
  acquirer: string
  currency: string
  minimum_amount: number
  installment_amount: number
}

export interface IInstallmentFormData {
  count: number
  interval: string
  installment_amount: number
}

export type ICardFormData = Omit<
  IPaymentDetails,
  'amount' | 'is_multiple_user' | 'should_authenticate'
> & {
  expirationDate?: string
  installment: IInstallmentFormData
}
