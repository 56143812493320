import { createContext, ReactNode, useEffect, useState } from 'react'
import isEmpty from 'helpers/is-empty'
import {
  destroySessionStorage,
  getSessionStorage,
} from 'helpers/session-storage'
import { getLocalStorage } from 'helpers/local-storage'
import { redirectToReferrer } from 'helpers/redirector'
import { sendLog, setLogUser } from 'helpers/log'
import { useRouter } from 'next/router'
import { getUsername, postRegistration } from 'services/user'
import { setCookie } from 'helpers/cookie'
import authConfig from 'configs/auth'
import { addDays } from 'helpers/date-time'
import {
  setPeopleProperties,
  setUserRegister,
} from 'helpers/analytics'
import trackEvent from '../trackers'
import { setFcmToken } from 'helpers/firebase'
import { getIdToken } from 'helpers/auth'
import { getErrorMessage } from 'helpers/error'
import { useProfileUserStore } from 'stores/domains/User'
import { useProfileUser } from 'hooks/domains/User'

interface AuthContextType {
  errorCode: string
  isLoading: boolean
  setErrorCode: Function
  setIsLoading: Function
}

const AuthContext = createContext<AuthContextType | null>(null)

interface AuthProps {
  children: ReactNode
}

const AuthProvider = ({ children }: AuthProps) => {
  const { pathname, query } = useRouter()
  const trackerData = { pathname, query }
  const idToken = getIdToken()
  const isNewUser = getSessionStorage('isNewUser')
  const isSso = getLocalStorage('authMethod') === 'google'
  const [isLoading, setIsLoading] = useState<boolean>(false)
  const [errorCode, setErrorCode] = useState<string>()
  const [dataUser, setDataUser] = useState(null)

  const refetchUsername = async () => {
    try {
      const response = await getUsername(idToken)
      setDataUser(response.data)
      return response?.data
    } catch (error) {
      Promise.reject(error)
    }
  }

  const { profileData } = useProfileUserStore((state) => ({
    profileData: state.profileData,
  }))

  const { refetch: refetchProfileData } = useProfileUser()
  const handleSkipPostProfileRegistration = async () => {
    try {
      const response = await postRegistration({
        username: dataUser.username,
      })
      if (response?.data?.code === 'SUCCESS') {
        setCookie(
          'user_id',
          response.data.data?.user_id,
          '/',
          addDays(authConfig.refreshTokenExpiryDays),
        )
        setCookie(
          'username',
          response.data.data?.username,
          '/',
          addDays(authConfig.refreshTokenExpiryDays),
        )
        setCookie(
          'email',
          response.data.data?.mail,
          '/',
          addDays(authConfig.refreshTokenExpiryDays),
        )
        setUserRegister(response.data.data?.user_id, {
          sign_up_method: 'unknown',
          user_id: response.data.data?.user_id,
          $email: response.data.data?.email,
          $name: response.data.data?.username,
          birth_date: null,
          user_type: 'supporter',
        })
        setLogUser(
          response.data.data?.user_id,
          response.data.data?.usename,
          response.data.data?.email,
        )

        // Condition to detecting user submit from skip button
        if (
          response.data.data?.month === '' &&
          response.data.data?.year === '' &&
          response.data.data?.day === ''
        ) {
          trackEvent.user(
            'skip_post_registration_process',
            trackerData,
            {
              user_id: response.data.data?.user_id,
              $name: response.data.data?.username,
              $email: response.data.data?.email,
              phone_number: null,
              user_type: 'supporter',
              gender: null,
              birth_date: null,
              coin_balance: 0,
              income_balance: 0,
            },
          )
        }

        trackEvent.user('sign_up_completed', trackerData, {
          refer_by_cl: false,
          referrer_username: null,
          referrer_code: null,
          use_referral_code_link: false,
          user_id: response.data.data?.user_id,
          $name: response.data.data?.username,
          $email: response.data.data?.email,
          phone_number: null,
          user_type: 'supporter',
          gender: null,
          birth_date: null,
          coin_balance: 0,
          income_balance: 0,
        })
        setPeopleProperties({
          refer_by_cl: false,
          referrer_username: null,
          referrer_code: null,
          use_referral_code_link: false,
        })
        setFcmToken('SIGN_IN').then(() => {
          destroySessionStorage('isNewUser')
          redirectToReferrer()
        })
      }
    } catch (error) {
      destroySessionStorage('isNewUser')
      sendLog(getErrorMessage(error))
    }
  }

  useEffect(() => {
    if (!isEmpty(idToken) && !profileData.user_id) {
      refetchProfileData()
    }
  }, [idToken])

  useEffect(() => {
    if (!isEmpty(idToken) && isNewUser && isSso) {
      refetchUsername()
    }
  }, [idToken])

  useEffect(() => {
    if (isNewUser && !isEmpty(dataUser)) {
      handleSkipPostProfileRegistration()
    }
  }, [dataUser])

  return (
    <AuthContext.Provider
      value={{
        errorCode,
        isLoading,
        setIsLoading,
        setErrorCode,
      }}
    >
      {children}
    </AuthContext.Provider>
  )
}

export { AuthContext, AuthProvider }
